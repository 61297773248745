<template>
  <section class="chips-list d-flex flex-md-row flex-column align-start">
    <div class="chips-list__list">
      <v-chip v-for="(chip, i) in categories" :key="i"
        class="mr-4 my-2 chip"
        close
        close-icon="mdi-close"
        color="#F7F7F7"
        text-color="#56565A"
        @click:close="removeChip(chip)">
          {{ chip.text }}
      </v-chip>
    </div>

    <div class="chips-list__clear mt-2">
      <v-btn v-if="categories.length > 1"
        class="chips-list__btn-remove"
        color="#034580"
        plain
        text
        @click="removeAllChip">
          Очистить  фильтр
      </v-btn>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChipsList',
  data: () => ({
    categories: [],
  }),
  computed: {
    ...mapGetters(['userTypes', 'Categories'])
  },
  methods: {
    ...mapActions(['fetchUserTypes', 'fetchCategories']),
    buildCategories() {
      this.categories = [];
      for (let key in this.$route.query) {
        const arr = this.$route.query[key].split(',');
        if ( key === 'category' || key === 'available_for' ) {
          arr.forEach(el => {
            this.categories.push({
              id: el,
              type: key,
              text: key === 'category' ? 
                this.Categories.find(obj => obj.id === Number(el)).name : 
                this.userTypes.find(obj => obj.id === Number(el)).name,
            })
          })
        }
      }
    },
    removeChip(item) {
      const chipType = this.$route.query[item.type];
      let arr = chipType.split(',');
      const i = arr.indexOf(item.id);
      arr.splice(i, 1);
      arr = arr.join(',');
      let query = { ...this.$route.query }

      if ( arr ) {
        query[item.type] = arr;
        this.$router.replace({ query: query })
      } else {
        delete query[item.type]
        this.$router.replace({ query: query })
      }
      
    },
    removeAllChip() {
      this.categories = [];
      this.$router.replace({ query: {} })
    },
  },
  async created() {
    await this.fetchUserTypes();
    await this.fetchCategories();
    this.buildCategories();
  },
  watch: {
    '$route.query': function() {
      this.buildCategories();
    }
  }
}
</script>

<style lang="scss" scoped>
.chips-list__btn-remove {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  font-weight: 400;
}
</style>

<style lang="scss">
.chip {
  button.v-chip__close.v-icon {
    color: #0A467E;
  }
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity: 1;
    filter: brightness(.75);
}
</style>
